import React, { useEffect } from "react";
import NavBar from "../components/navigation-header";
import Footer from "../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGooglePlay,
  faAppStoreIos,
} from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";
import {
  MetaTitle,
  MetaDescription,
  MetaKeywords,
  MetaOGTitle,
  MetaOGDescription,
  MetaURL,
  MetaImage,
} from "../components/meta-tags";

export default function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = MetaTitle();
  const description = MetaDescription();
  const metaKeywords = MetaKeywords();
  const metaOGTitle = MetaOGTitle();
  const metaOGDesc = MetaOGDescription();
  const metaURL = MetaURL();
  const metaImage = MetaImage();

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href={metaURL} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaOGTitle} />
        <meta property="og:description" content={metaOGDesc} />
        <meta property="og:url" content={metaURL} />
        <meta property="og:image" content={metaImage} />
      </Helmet>

      <NavBar />

      <div>
        <center>
          <h1 className="app-topic">Download Our WASTE 2 VALUE APP</h1>
        </center>

        <div style={{ marginBottom: "220px" }}>
          <div className="two-column-para">
            <div className="column-para column1-para">
              <center>
                <div className="app-para-left-side">
                  <div className="app-icon">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.plasticdisposalapp"
                      aria-label="icon"
                      className="disabled-link"
                    >
                      <FontAwesomeIcon icon={faGooglePlay} />
                    </a>
                  </div>
                  <p className="app-para">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.plasticdisposalapp"
                      className="disabled-link"
                    >
                      Android App
                    </a>
                  </p>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.plasticdisposalapp"
                    className="app-button disabled-link"
                  >
                    DOWNLOAD
                  </a>
                </div>
                <br />
              </center>
            </div>

            <div className="column-para column2-para">
              <center>
                <div className="app-para-right-side">
                  <div className="app-icon">
                    <a
                      href="https://apps.apple.com/lk/app/waste-2-value/id1556730694"
                      aria-label="icon"
                    >
                      <FontAwesomeIcon icon={faAppStoreIos} />
                    </a>
                  </div>
                  <p className="app-para">
                    <a href="https://apps.apple.com/lk/app/waste-2-value/id1556730694">
                      iOS App
                    </a>
                  </p>

                  <a
                    href="https://apps.apple.com/lk/app/waste-2-value/id1556730694"
                    className="app-button"
                  >
                    DOWNLOAD
                  </a>
                </div>
                <br />
              </center>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}
